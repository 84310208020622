import React from 'react';
import { useGetRole } from '../../../utils/hooks';
import { generateImageUrl } from '../../../utils/helpers';
import SystemList from '../../common/SystemList';
import BackButton from '../../common/ui/Buttons/BackButton';
import './index.scss';

const RolePage = () => {

  const role = useGetRole();

  return (
    <div className="role-page">
      <div className='role-page__header'>
        <div className='role-page__header-container'>
          <BackButton />
          <div className='role-page__header-title'>
            <p>{role?.name}</p>
          </div>
        </div>
        <div className='role-page__header-layer' />
        <img className='role-page__header-image'
          src={generateImageUrl(role?.image)}
          alt='bg-img'
        />
      </div>
      <div className='role-page__content'>
        <SystemList 
          systems={role?.systems}
        />
      </div>
    </div>
  );
};

export default RolePage;