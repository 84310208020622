import React from 'react';
import { getSiteSettings } from '../../api/services/SiteSettings';

export const useGetSiteSettings = () => {
  const [siteSettings, setSiteSettings] = React.useState();

  const fetchData = React.useCallback(async () => {
    try {
      const res = await getSiteSettings();
      setSiteSettings(res.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return siteSettings;
};
