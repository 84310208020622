import React from 'react';
import * as classnames from 'classnames';
import ShapeFolder from '../ui/Shapes/ShapeFolder';
import './index.scss';

const RoleListItem = props => {
  const {
		role = {},
		onClick = () => {},
    selected,
	} = props;

  return (
      <div
        className={classnames(
          'role-list-item',
          selected && '-selected',
        )}
        onClick={onClick}
      >
        <ShapeFolder 
          className="role-list-item__folder"
          selectedColor={selected ? role.color : null} 
          role={role}
        />
        <div 
          className={classnames(
            'role-list-item__icon',
            role?.color && `-${role.color}`,
          )}
          data-content={role.icon}
        ></div>
        <div className='role-list-item__title'>{role.name}</div>
      </div>
  )
};

export default RoleListItem;