const setError = (state, { error }) => {
  if (error && error.text) {
    state.error = error;
  }
  return { ...state };
};

const nullError = (state) => {
  state.error = { text: '', code: 0 };
  return { ...state };
};

const actions = {
  setError,
  nullError,
};

export const reducer = (state, action) => {
  if (action && action.type && actions[action.type]) {
    return actions[action.type](state, action);
  }
  return { ...state };
};