import axios from 'axios';
import Paths from '../../../utils/paths';
import { API_URL } from '../../../utils/constants';

export const getLandingPage = async () => {
  const response = await axios.get(
    `${API_URL}/${Paths.LandingPage.landingPage}`,
  );
  
  return response;
};