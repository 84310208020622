export const API_BASE_URL = process.env.REACT_APP_URL;
export const API_URL = `${API_BASE_URL}/api/frontend`;

export const PATHS = {
    root: '/',
    notFound: '/not-found',
    roles: '/roles',
    systems: '/systems',
    params: {
        roleSlug: '/:roleSlug',
        systemSlug: '/:systemSlug',
    }
}