import React from 'react';
import { useNavigate } from 'react-router';
import { PATHS } from '../../../utils/constants';
import { generateImageUrl } from '../../../utils/helpers';
import BackButton from '../../common/ui/Buttons/BackButton';
import Button from '../../common/ui/Buttons/Button';
import RoleList from '../../common/RoleList'
import './index.scss';

const RolesPage = () => {
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = React.useState(null);

  return (
    <div className="roles-page">
      <div className='roles-page__header'>
        <div className='roles-page__header-container'>
          <BackButton />
          <div className='roles-page__header-title'>
            <p>{selectedRole ? selectedRole.name : 'Choose your role'}</p>
          </div>
        </div>
        <div className='roles-page__header-layer' />
        { selectedRole ? (
          <img className='roles-page__header-image'
            src={generateImageUrl(selectedRole.image)}
            alt='bg-img'
          />
        ) : null }
      </div>
      <div className='roles-page__content'>
        <RoleList 
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
        />
        <Button 
          label="Proceed"
          className="-floating"
          disabled={!selectedRole}
          onClick={() => navigate(`${PATHS.roles}/${selectedRole?.slug}`)}
        />
      </div>
    </div>
  );
};

export default RolesPage;