import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const SlickDots = props => {
  const { 
    dots,
    color
  } = props;

  return (
    <div className="slick-dots">
      <ul className={classnames(
          'slick-dots__list',
          `-${color}`,
        )}
      >
        {dots}
      </ul>
    </div>
  );
};

export default SlickDots;
