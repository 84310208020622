import React from 'react'
import { useNavigate } from 'react-router';
import { PATHS } from '../../../utils/constants';
import * as classnames from 'classnames';
import ButtonInfo from '../../common/ui/Buttons/ButtonInfo';
import ButtonSystem from '../../common/ui/Buttons/ButtonSystem';
import './index.scss';

const SystemListItem = props => {
  const {
		system={},
    isActive,
    setActiveSystem,
	} = props;

  const navigate = useNavigate();

  const openUrl = () => {
		window.open(system.url, '_blank');
	}

  return (
      <div
        className={classnames(
          'system-list-item',
          isActive && '-open',
        )}
      >
        <div
          className='system-list-item__header'
          onClick={setActiveSystem}
        >
          <div className='system-list-item__left'>
            <div className={classnames(
                'system-list-item__icon',
                system?.color && system.color,
              )} 
              data-content={system.icon}
            />
            <div className='system-list-item__title'>
              {system.name}
            </div>
          </div>
          <div className='system-list-item__arrow' />
        </div>
        <div className='system-list-item__body'>
          <div className='system-list-item__text'>
            {system.description}
          </div>
          <div className='system-list-item__buttons'>
            <ButtonInfo
              label="Info"
              onClick={() => navigate(`${PATHS.systems}/${system?.slug}`)}
            ></ButtonInfo>
            <ButtonSystem 
              label="System"
              onClick={openUrl}
            ></ButtonSystem>
          </div>
        </div>
      </div>
  )
};

export default SystemListItem;