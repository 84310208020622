export default class Paths {
  static SiteSettings = class {
    static siteSettings = 'site-settings';
  };

  static LandingPage = class {
    static landingPage = 'landing-page';
  };

  static Roles = class {
    static roles = 'positions';
  };

  static Systems = class {
    static systems = 'systems';
  };
}
