import { useState, useEffect, useMemo, useCallback } from "react";

export const useVideoPlayer = (videoElement) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  
  const [playerState, setPlayerState] = useState({
    isFirstStart: true,
    isPlaying: false,
    progress: 0,
    isMuted: false,
  });

  const togglePlay = useCallback(() => {
    setPlayerState({
      ...playerState,
      isFirstStart: playerState.isFirstStart && false,
      isPlaying: !playerState.isPlaying,
    });
  }, [playerState]);

  useEffect(() => {
    playerState.isPlaying
      ? videoElement.current.play()
      : videoElement.current.pause();
  }, [playerState.isPlaying, videoElement]);

  const handleOnTimeUpdate = useCallback(() => {
    const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
    setPlayerState({
      ...playerState,
      progress,
    });
  }, [playerState, videoElement]);

  const handleVideoProgress = useCallback((event) => {
    const manualChange = Number(event.target.value);
    videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange;
    setPlayerState({
      ...playerState,
      progress: manualChange,
    });
  }, [playerState, videoElement]);

  const toggleMute = useCallback(() => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted,
    });
  }, [playerState]);

  useEffect(() => {
    playerState.isMuted
      ? (videoElement.current.muted = true)
      : (videoElement.current.muted = false);
  }, [playerState.isMuted, videoElement]);

  const toggleFullscreen = useCallback((videoWrapperElement) => {
    if (videoWrapperElement.current) {
      if (!isFullScreen) {
        if (videoWrapperElement.current.requestFullscreen) {
          videoWrapperElement.current.requestFullscreen();
        } else if (videoWrapperElement.current.webkitRequestFullscreen) {
          videoWrapperElement.current.webkitRequestFullscreen();
        } else if (videoWrapperElement.current.msRequestFullscreen) {
          videoWrapperElement.current.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
      
      setIsFullScreen(!isFullScreen);
    }
  }, [isFullScreen]);

  return useMemo(() => ({
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    toggleMute,
    toggleFullscreen,
    isFullScreen
  }), [ 
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    toggleMute,
    toggleFullscreen,
    isFullScreen
  ]); 
};
