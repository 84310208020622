import React from 'react'
import RoleListItem from '../RoleListItem';
import { useGetRoles } from '../../../utils/hooks';
import './index.scss';

const RoleList = props => {
  
  const {
    selectedRole,
    setSelectedRole=()=>{},
  } = props;

  const roles = useGetRoles();

  return (
    <div className='role-list'>
      {roles?.map(role => (
        <RoleListItem
          key={role.id}
          role={role}
          selected={role.id === selectedRole?.id}
          onClick={() => setSelectedRole(role)}
        />
      ))}
    </div>
  )
}

export default RoleList;