import React from 'react';
import Slider from 'react-slick';
import { generateImageUrl } from '../../../utils/helpers';
import * as classnames from 'classnames';
import SlickDots from '../ui/ReactSlickCustom/SlickDots';
import './index.scss';

const SystemBlockImage = props => {
  const {
		data = {},
    color
	} = props;

  const settings = {
    className: 'slider-settings',
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => <SlickDots dots={dots} color={color} />,
  }

  return (
    <>
      <div className="system-block-image">
        <div className="system-block-image__title">
          <h1>{data.imagesTitle}</h1>
        </div>
        <div className="system-block-image__slider">
          <Slider {...settings}>
            {data?.images.map((image, i) => (
              <div key={i} className="system-block-image__slider-container">
                <img
                  className="system-block-image__slider-image"
                  src={generateImageUrl(image)}
                  alt="bg-img"
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      { data.separator === true ? (
        <div 
          className={classnames(
          'system-block-separator',
          color && `-${color}`,
        )} />
      ) : null }
    </>
      
  )
};

export default SystemBlockImage;