import { API_BASE_URL } from "./constants";

export const findColorsFromPalette = (colorName, colorPalette) => {
  const palette = {};

  for (const key of Object.keys(colorPalette)) {
    if (key.includes(colorName)) {
      palette[key] = colorPalette[key];
    }
  }

  return palette;
}

export const generateImageUrl = (images={}) => {
  const { small, medium, large, original } = images;

  if (large) {
    return `${API_BASE_URL}${large}`;
  }

  if (medium) {
    return `${API_BASE_URL}${medium}`;
  }

  if (small) {
    return `${API_BASE_URL}${small}`;
  }

  if (original) {
    return `${API_BASE_URL}${original}`;
  }

  return '';
}

export const getImageUrl = (url) => {
  return `${API_BASE_URL}${url}`;
}