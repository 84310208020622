import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { Helmet } from "react-helmet";
import { getImageUrl } from './utils/helpers';
import { useGetSiteSettings } from './utils/hooks';
import { ContextProvider } from './storage/context';
import { reducer } from './storage/reducer/reducer';
import { PATHS } from './utils/constants';
import Error404Page from './components/pages/Error404Page';
import LandingPage from './components/pages/LandingPage';
import RolesPage from './components/pages/RolesPage';
import RolePage from './components/pages/RolePage';
import SystemPage from './components/pages/SystemPage';
import defaultFavicon from './assets/images/favicon/favicon.ico';

const App = () => {
  const {
    root,
    notFound,
    roles,
    systems,
    params: {
      roleSlug,
      systemSlug
    }
  } = PATHS;

  const settings = useGetSiteSettings();

  return (
    <ContextProvider
      initialState={{ error: null }}
      reducer={reducer}
    >
      <Helmet>
          <title>{settings?.tab_title}</title>
          <link rel="icon" href={settings?.favicon ? getImageUrl(settings?.favicon) : defaultFavicon} />
      </Helmet>
      <Router>
        <Routes>
          <Route path={notFound} exact element={<Error404Page />} />
          <Route index path={root} exact element={<LandingPage />} />
          <Route path={roles} exact element={<RolesPage />} />
          <Route path={`${roles}${roleSlug}`} exact element={<RolePage />} />
          <Route path={`${systems}${systemSlug}`} exact element={<SystemPage />} />
          <Route path="*" element={<Navigate replace to={notFound} />} />
        </Routes>
      </Router>
    </ContextProvider>
  );
};

export default App;
