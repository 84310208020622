import React from 'react';
import { getLandingPage } from '../../api/services/LandingPage';

export const useGetLandingPage = () => {
  const [landingPage, setLandingPage] = React.useState();

  const fetchData = React.useCallback(async () => {
    try {
      const res = await getLandingPage();
      setLandingPage(res.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return landingPage;
};
