import React from 'react';
import { useParams } from 'react-router-dom';
import { getSystem } from '../../api/services/Systems';

export const useGetSystem = () => {
  const [system, setSystem] = React.useState();

  const { systemSlug } = useParams();

  const fetchData = React.useCallback(async () => {
    try {
      const res = await getSystem(systemSlug);
      setSystem(res.data.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return system;
};
