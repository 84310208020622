import React from 'react'
import { useResolveScreenBreakpoints } from '../../../utils/hooks';
import SystemListItem from '../SystemListItem';
import { BREAKPOINTS, BREAKPOINT_KEYS } from '../../../utils/breakpoints';
import './index.scss';

const SystemList = props => {

  const {
    systems
  } = props;

  const [activeSystem, setActiveSystem] = React.useState(null);

  const { screenSize } = useResolveScreenBreakpoints(BREAKPOINTS);

  const columns = React.useMemo(() => {
    switch (screenSize) {
      case BREAKPOINT_KEYS.xs:
        return 1;
      case BREAKPOINT_KEYS.sm:
        return 1;
      case BREAKPOINT_KEYS.md:
        return 1;
      case BREAKPOINT_KEYS.lg:
        return 2;
      case BREAKPOINT_KEYS.xl:
        return 2;
      case BREAKPOINT_KEYS.xxl:
        return 2;
      case BREAKPOINT_KEYS.xxxl:
        return 3;
      default:
        return 1;
    }
  }, [screenSize]);

  return (
    <div className='system-list'>
      <div className='system-list__column'>
        {systems?.map((system, i) => {
          return i % columns === 0 ? (
            <SystemListItem
              key={system.id}
              system={system}
              isActive={system.id === activeSystem?.id}
              setActiveSystem={() => setActiveSystem(system.id === activeSystem?.id ? null : system)}
            />
          ) : null
        })}
      </div>
      { columns > 1 ? (
        <div className='system-list__column'>
          {systems?.map((system, i) => {
            return i % columns === 1 ? (
              <SystemListItem
                key={system.id}
                system={system}
                isActive={system.id === activeSystem?.id}
                setActiveSystem={() => setActiveSystem(system.id === activeSystem?.id ? null : system)}
              />
            ) : null
          })}
        </div>
      ) : null }
      { columns > 2 ? (
        <div className='system-list__column'>
          {systems?.map((system, i) => {
            return i % columns === 2 ? (
              <SystemListItem
                key={system.id}
                system={system}
                isActive={system.id === activeSystem?.id}
                setActiveSystem={() => setActiveSystem(system.id === activeSystem?.id ? null : system)}
              />
            ) : null
          })}
        </div>
      ) : null }
    </div>
  )
}

export default SystemList;