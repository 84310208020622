import React from 'react';
import { useNavigate } from 'react-router';
import { useGetLandingPage } from '../../../utils/hooks';
import { getImageUrl } from '../../../utils/helpers';
import fjordhotell from '../../../assets/images/logos/fjordhotell.svg';
import nohao from '../../../assets/images/logos/nohao.svg';
import Button from '../../common/ui/Buttons/Button';
import './index.scss';

const LandingPage = () => {
  const navigate = useNavigate();
  const info = useGetLandingPage();

  return (
    <div className="landing-page">
      <div className="landing-page__container">
        <div className="landing-page__logo">
          {info?.logo ? <img className="landing-page__logo-image" src={getImageUrl(info?.logo)} alt="hotel-logo" /> : '' }
        </div>
        <div className="landing-page__nohao">
          <img className="landing-page__nohao-image" src={nohao} alt="nohao" />
        </div>
        <div className="landing-page__main-text">
          <p>{info?.welcome ? info?.welcome : "Welcome,"}</p>
          <p>{info?.text ? info?.text : "let's teach you how to master your role"}</p>
        </div>
        <div className="landing-page__sub-text">
          <p>{info?.subtext ? info?.subtext : "Learn how to execute your tasks at the workplace from A to Z."}</p>
        </div>
        <Button 
          label="Get started"
          onClick={() => navigate("/roles")}
          ></Button>
      </div>
    </div>
  );
};

export default LandingPage;