import React from 'react';
import { API_BASE_URL } from '../../../utils/constants';
import COLORS from '../../../utils/colors';
import * as classnames from 'classnames';
import './index.scss';

import { useVideoPlayer } from "../../../utils/hooks";

const SystemBlockVideo = props => {
  const {
		data,
    color
	} = props;

  const videoElementWrapper = React.useRef(null);
  const videoElement = React.useRef(null);
  
  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    toggleMute,
    toggleFullscreen,
    isFullScreen
  } = useVideoPlayer(videoElement);
  
  const sliderAdjustment = playerState.progress * 0.16;
  
  return (
    <>
      <div className="system-block-video">
        <div className="system-block-video__title">
          <h1>{data.videoTitle}</h1>
        </div>
        <div 
          ref={videoElementWrapper}
          className={classnames(
            'system-block-video__content',
            color && `-${color}`,
            playerState.isFirstStart && '-first-play',
            isFullScreen && '-full-screen'
          )}
        >
          <div 
            className={classnames(
              'system-block-video__layer',
              !playerState.isFirstStart && '-hide'
            )}
            onClick={togglePlay}
          />
          <video
            className="system-block-video__video"
            src={`${API_BASE_URL}${data.videoUrl}`}
            ref={videoElement}
            onTimeUpdate={handleOnTimeUpdate}
            loop
          />
          <div 
            className={classnames(
              'system-block-video__controls',
              !playerState.isPlaying && !playerState.isFirstStart && '-shown'
            )}
          >
            <button 
              className={classnames(
                'system-block-video__controls-play-button',
                !playerState.isPlaying ? '-play' : '-pause',
              )}
              onClick={togglePlay}
            />
            <input
              className="system-block-video__controls-progress"
              type="range"
              min="0"
              max="100"
              value={playerState.progress}
              onChange={(e) => handleVideoProgress(e)}
              style={{
                background: `
                  linear-gradient(to right, ${COLORS[color]} calc(8px + ${playerState.progress}% - ${sliderAdjustment}px),
                  ${COLORS['white']} calc(-8px + ${playerState.progress}% - ${sliderAdjustment}px)
                `
              }}
            />
            <button 
              className={classnames(
                'system-block-video__controls-mute-button',
                !playerState.isMuted ? '-mute' : '-unmute',
              )} 
              onClick={toggleMute}
            />
            <button 
              className="system-block-video__controls-full-screen-button"
              onClick={() => toggleFullscreen(videoElementWrapper)}
            />
          </div>
        </div>
      </div>
      { data.separator === true ? (
        <div 
          className={classnames(
          'system-block-separator',
          color && `-${color}`,
        )} />
      ) : null }
    </>
  )
};

export default SystemBlockVideo;