import React from 'react';
import BackButton from '../../common/ui/Buttons/BackButton';
import SystemInformation from '../../common/SystemInformation';
import SystemBlockList from '../../common/SystemBlockList';
import { useGetSystem } from '../../../utils/hooks';
import { generateImageUrl } from '../../../utils/helpers';
import './index.scss';

const SystemPage = () => {

  const system = useGetSystem();

  return (
    <div className="system-page">
      <div className='system-page__header'>
        <div className='system-page__header-container'>
          <BackButton />
        </div>
        <div className='system-page__header-layer' />
        <img className='system-page__header-image'
          src={generateImageUrl(system?.image)}
          alt='bg-img'
        />
      </div>
      <div className='system-page__content'>
        <div className='system-page__content-container'>
          <SystemInformation system={system}/>
          <SystemBlockList system={system} />
        </div>
      </div>
    </div>
  );
};

export default SystemPage;