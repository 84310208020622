import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const SystemInformation = props => {
  const {
		system = {},
	} = props;

  return (
      <div className='system-information'>
        <div className='system-information__header'>
          <div 
            className={classnames(
              'system-information__header-icon',
              system?.color && `-${system.color}`,
            )}
            data-content={system.icon}
          />
          <div className='system-information__header-content'>
            <div className='system-information__header-subtitle'>
              Information
            </div>
            <div className='system-information__header-title'>
              {system.name}
            </div>
          </div>
        </div>
        <div 
          className='system-information__tags'
        >
          {system?.tags?.map((tag, i) => (
            <div
              key={i}
              className={classnames(
                'system-information__tag',
                system?.color && `-${system.color}`,
              )}
            >
              <p>{tag.name}</p>
            </div>
          ))}
        </div>
      </div>
  )
};

export default SystemInformation;