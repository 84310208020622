import React from 'react';
import { useNavigate } from "react-router-dom";
import * as classnames from 'classnames';
import './index.scss';

const BackButton = (props) => {
  const { className, disabled } = props;

  const navigate = useNavigate();

  return (
    <div
      className={classnames(
        'back-button',
        className && className,
        disabled && '-disabled',
      )}
      onClick={() => navigate(-1)}
    >
    </div>
  );
};

export default BackButton;
