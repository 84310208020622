const COLORS = {
  // ------------------ MAIN COLOR PALETTE ------------------ //

  primary: '#00AAE7',
  secondary: '#DB00FF',

  // ------------------ MISCELLANEOUS COLOR PALETTE ------------------ //

  white: '#ffffff',
  whiteDark: '#FCFCFF',
  black: '#000000',

  grey: '#666666',
  greyDark: '#767F9D',

  // ------------------ ITEM COLOR PALETTE ------------------ //
  pink: '#FF3395',
  pinkLight: '#FF65AF',
  pinkLighter: '#FE97CA',
  pinkDark: '#9A1555',
  pinkShadow: '#FF339580',

  yellow: '#FFBA33',
  yellowLight: '#FFCA66',
  yellowLighter: '#FDDA98',
  yellowDark: '#BD871D',
  yellowShadow: '#FFBA3380',

  green: '#3DE3A6',
  greenLight: '#55E7B1',
  greenLighter: '#9DF0D2',
  greenDark: '#057B50',
  greenShadow: '#3DE3A680',

  blue: '#00AAE7',
  blueLight: '#658EFF',
  blueLighter: '#A7BEFF',
  blueDark: '#004C67',
  blueShadow: '#00AAE780',
};

export default COLORS;