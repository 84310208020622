import React from 'react';
import SystemBlockText from '../../common/SystemBlockText';
import SystemBlockImage from '../../common/SystemBlockImage';
import SystemBlockVideo from '../../common/SystemBlockVideo';
import './index.scss';

const SystemBlockList = props => {
  const {
		system = {},
	} = props;

  const renderSystemBlocks = React.useCallback((block) => {
    if (!block) {
      return null;
    }
    switch (block.type) {
      case 'system-block.text':
        return (
          <SystemBlockText data={block} color={system.color}/>
        );
      case 'system-block.images':
        return (
          <SystemBlockImage data={block} color={system.color} />
        );
      case 'system-block.video':
        return (
          <SystemBlockVideo data={block} color={system.color} />
        );
      default:
        return null;
    }
  }, [system]);

  return (
    <div className="system-block-list">
      {system.blocks?.map((block, i) => {
        return <React.Fragment key={i}>{renderSystemBlocks(block)}</React.Fragment>
      })}
    </div>
  )
};

export default SystemBlockList;