import React from 'react';
import './index.scss';

const Error404Page = () => {

  return (
    <div className="error-404-page">
      Error 404 :/
    </div>
  );
};

export default Error404Page;