import React from 'react';
import { getRoles } from '../../api/services/Roles';

export const useGetRoles = () => {
  const [roles, setRoles] = React.useState();

  const fetchData = React.useCallback(async () => {
    try {
      const res = await getRoles();
      setRoles(res.data.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return roles;
};
