import React from 'react';
import * as classnames from 'classnames';
import './index.scss';

const ButtonSystem = (props) => {
	const {
		label = '',
		type = 'button',
		className = '',
		onClick = () => { },
		disabled = false
	} = props;

	

	return (
		<button
			type={type}
			className={classnames(
				'button-system',
				className && className,
				disabled && '-disabled',
			)}
			onClick={onClick}
		>
		{label}
		</button>
	)
}

export default ButtonSystem;