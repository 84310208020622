import React from 'react';
import { useParams } from 'react-router-dom';
import { getRole } from '../../api/services/Roles';

export const useGetRole = () => {
  const [role, setRole] = React.useState();

  const { roleSlug } = useParams();

  const fetchData = React.useCallback(async () => {
    try {
      const res = await getRole(roleSlug);
      setRole(res.data.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return role;
};
