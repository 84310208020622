import axios from 'axios';
import Paths from '../../../utils/paths';
import { API_URL } from '../../../utils/constants';

export const getRoles = async () => {
  const response = await axios.get(
    `${API_URL}/${Paths.Roles.roles}`,
  );
  
  return response;
};

export const getRole = async (roleSlug) => {
  const response = await axios.get(
    `${API_URL}/${Paths.Roles.roles}/${roleSlug}`,
  );
  
  return response;
};