import React from 'react'
import * as classnames from 'classnames';
import COLORS from '../../../../../utils/colors';
import './index.scss'

const ShapeFolder = props => {
  const {
    className,
    selectedColor,
    role,
  } = props;

  const lightColor = role.color ? COLORS[`${role.color}Light`] : COLORS.white;
  const lighterColor = role.color ? COLORS[`${role.color}Lighter`] : COLORS.white;

  return (
    <svg className={classnames('shape-folder', className && className, selectedColor && selectedColor)} width="160" height="120" viewBox="0 0 160 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="97.354" y="13.7623" width="50" height="50" rx="5" transform="rotate(-3.90886 97.354 13.7623)" fill={lightColor} />
      <rect x="82.9482" y="16.9775" width="50" height="50" rx="5" transform="rotate(-16.2952 82.9482 16.9775)" fill={lighterColor} />
      <path d="M145 119.25H15C7.12994 119.25 0.75 112.87 0.75 105V15C0.75 7.12997 7.12994 0.750031 15 0.750031H75.807C78.4126 0.750031 80.9684 1.46444 83.1963 2.81557L107.026 17.2671C109.488 18.7604 112.313 19.55 115.193 19.55H145C152.87 19.55 159.25 25.93 159.25 33.8V105C159.25 112.87 152.87 119.25 145 119.25Z" fill="white" />
      <path d="M145 119.25H15C7.12994 119.25 0.75 112.87 0.75 105V15C0.75 7.12997 7.12994 0.750031 15 0.750031H75.807C78.4126 0.750031 80.9684 1.46444 83.1963 2.81557L107.026 17.2671C109.488 18.7604 112.313 19.55 115.193 19.55H145C152.87 19.55 159.25 25.93 159.25 33.8V105C159.25 112.87 152.87 119.25 145 119.25Z" fill={`url(#item-id-${role.id})`} />
      <path d="M145 119.25H15C7.12994 119.25 0.75 112.87 0.75 105V15C0.75 7.12997 7.12994 0.750031 15 0.750031H75.807C78.4126 0.750031 80.9684 1.46444 83.1963 2.81557L107.026 17.2671C109.488 18.7604 112.313 19.55 115.193 19.55H145C152.87 19.55 159.25 25.93 159.25 33.8V105C159.25 112.87 152.87 119.25 145 119.25Z" stroke={selectedColor ? COLORS[selectedColor] : COLORS.white} strokeWidth="1.5" />
      <defs>
        <linearGradient id={`item-id-${role.id}`} x1="79.7583" y1="120" x2="79.7583" y2="4.11756e-05" gradientUnits="userSpaceOnUse">
          <stop stopColor={selectedColor ? COLORS[selectedColor] : COLORS.white} stopOpacity="0.15" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default ShapeFolder;
