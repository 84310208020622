import React from 'react';
import ReactMarkdown from 'react-markdown';
import * as classnames from 'classnames';
import './index.scss';

const SystemBlockText = props => {
  const {
		data = {},
    color,
	} = props;

  return (
    <>
      <div className="system-block-text">
        <ReactMarkdown>{data.text}</ReactMarkdown>
      </div>
      { data.separator === true ? (
        <div 
          className={classnames(
          'system-block-separator',
          color && `-${color}`,
        )} />
      ) : null }
    </>
  )
};

export default SystemBlockText;